const filterLength = {
    init() {
        $(document).on('ready', () => {
            if ($('.filter').length > 0) {
                const filterItem = $('.filter__item');
                if (filterItem.length < 2) {
                    filterItem.hide();
                }
            }
        });
    },
};

export default filterLength;
