const textBlock = {
    slider: [],
    initBxSlider() {
        if ($.prototype.bxSlider) {
            $('.text_block__items').each(function () {
                textBlock.slider.push($(this).bxSlider({
                    useCSS: false,
                    maxSlides: 1,
                    infiniteLoop: true,
                    pager: true,
                    autoHover: false,
                    adaptiveHeight: true,
                    auto: false,
                    speed: 500,
                    pause: 3000,
                    controls: false,
                    swipeThreshold: 100,
                }));
            });
        }
    },
    destroyBxSlider() {
        if (textBlock.slider.length > 0) {
            for (let i = 0; i < textBlock.slider.length; i += 1) {
                textBlock.slider[i].destroySlider();
            }
        }
    },
};

export default textBlock;
