import 'jquery-mask-plugin/dist/jquery.mask.min';
import 'jquery-validation/dist/jquery.validate';
import validation from './validation';

const form = {
    init() {
        $('.js--phone-num').mask('+38 (099) 999-99-99');
        
        $('.js--show-password').click(function () {
            const type = $(this).siblings('.input').attr('type');
            console.log(type);
            $(this).siblings('.input').attr('type',`${type == 'text' ? 'password' : 'text'}`)
        });
        
        $('input[type="text"]').focus(function () {
            if ($(this).hasClass('js--phone-num')) {
                if (!$(this).hasClass('js--mask_inited')) {
                    $(this).addClass('js--mask_inited').mask('+38 (099) 999-99-99');
                }
            }
        });
        validation.init();
    },
};

export default form;
