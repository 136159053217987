const formHandler = {
    form: undefined,
    setCurrentForm(validatedForm) {
        this.form = validatedForm.currentForm;
        this.simpleUser = $(this.form).find('.date-select').hasClass('js--birthday');
        return this;
    },
    nds() {
        const $nds = $(this.form).find('#nds');
        if ($nds.length > 0) {
            return $nds.is(':checked') ? '1' : '0';
        }
        return false;
    },
    getExtraPhones() {
        const $phones = $(this.form).find('.js--extra_phone').not('.hidden');
        let phonesVal = '';
        if ($phones.length > 0) {
            $phones.each(function () {
                phonesVal += `${$(this).find('input').val()};`;
            });
            return phonesVal;
        }
        return false;
    },
    prepareData() {
        const selects = $(this.form).find('.js--dropdown-selected');
        const days = selects.filter('[name="days"]').val();
        const years = selects.filter('[name="years"]').val();
        const months = selects.filter('[name="months"]').val();
        const city = selects.filter('[name="city"]').val();
        const certificateDate = `&certificate_date=${years}-${months}-${days}`;
        const birthday = `&years=${years}&months=${months}&days=${days}`;
        let serialized = $(this.form).find('input:not(.js--dropdown-selected), textarea').not('.js--dropdown-selected').serialize();
        const nds = formHandler.nds();
        const phones = formHandler.getExtraPhones();
        const taxForm = selects.filter('[name="tax_form"]').val();
        serialized += this.simpleUser ? birthday : certificateDate;
        if (nds) {
            serialized += `&nds=${nds}`;
        }
        if (phones) {
            serialized += `&phones[]=${phones}`;
        }
        if (city) {
            serialized += `&city=${city}`;
        }
        if (taxForm) {
            serialized += `&tax_form=${taxForm}`;
        }
        serialized += '&ajax=true';

        return serialized;
    },
    insertErrorList(errors) {
        if (typeof errors === 'Array') {
            return `<ol>${errors.map((error) => `<li>${error}</li>`)}</ol>`;
        }
        let children = '';
        for (const err in errors) {
            children += `<li>${errors[err]}</li>`;
        }
        return `<ol>${children}</ol>`;
    },
    auth() {
        const $form = $(formHandler.form);
        const $errorWrapper = $form.find('.js--error-list');
        $errorWrapper.slideUp();
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: formHandler.prepareData(),
            beforeSend() {
                $form.addClass('preloader');
            },
            complete() {
                $form.removeClass('preloader');
            },
            success(response) {
                const rsp = JSON.parse(response);
                // debugger;
                if (rsp.hasError) {
                    $errorWrapper.html(formHandler.insertErrorList(rsp.errors)).slideDown('slow.');
                } else {
                    location.reload();
                }
            },

        });
    },
    defaultSubmit() {
        const $form = $(formHandler.form);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
            beforeSend() {
                $form.addClass('preloader');
            },
            complete() {
                $form.removeClass('preloader');
            },
            success(response) {
                // const rsp = JSON.parse(response);
                // debugger;
                location.reload();
                // if (rsp.hasError) {
                //     $errorWrapper.html(formHandler.insertErrorList(rsp.errors)).slideDown('slow.');
                // } else {
                //     location.reload();
                // }
            },

        });
    },
    callbackForm() {
        const $form = $(this.form);
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
            beforeSend() {
                $('.js--preload').addClass('button--preloader');
            },
            success(rsp) {
                const response = JSON.parse(rsp);
                if (response.hasError) {
                    formHandler.showErrorMessage(response.errors);
                } else {
                    formHandler.showCallBackSuccessMessage();
                    $form[0].reset();
                }
            },
            complete() {
                $('.js--preload').removeClass('button--preloader');
            },
        });
    },
    showErrorMessage(errors) {
        const gerr = $(this.form).find('.global_form_errors').html('');
        gerr.slideUp('slow');
        $.each(errors, (key, val) => {
            const $input = $(formHandler.form).find(`input[name=${key}]`);
            if ($input.length > 0) {
                $input.closest('.form-group').addClass('form-group--error');
                $input.siblings('.form__error_wrapper').html(val);
            } else {
                gerr.append(`<li>${val}</li>`).slideDown('slow');
            }
        });
    },
    showCallBackSuccessMessage() {
        const $modalSuccess = $('#modal_callback_success');
        $modalSuccess.modal('show');
    },
    cleanForm() {
        $(this.form).find('input').val('');
        $(this.form).find('textarea').val('');
    },
    reInitForm() {
        formHandler.cleanForm();
        setTimeout(() => {
            $(formHandler.form).closest('.js--parent-form').removeClass('show_response');
        }, 5000);
    },
};

export default formHandler;
