let recent_post_carousel;
let recent_post_carousel_items;
function countItemsRecentPost() {
    const recentPosts = $('#beesblog_column');
    if (recentPosts.width() < 736) {
        recent_post_carousel_items = 1;
    }
    if (recentPosts.width() >= 736) {
        recent_post_carousel_items = 2;
    }
    if (recentPosts.width() >= 990) {
        recent_post_carousel_items = 3;
    }
}

$(document).ready(() => {
    initBeesblogRecentPostSlider();

});
$(window).resize(() => {
    if ($('#beesblogRecentPostSlider').length) {
        resizeBeesblogRecentPostSlider();
    }
});

function initBeesblogRecentPostSlider() {
    countItemsRecentPost();
    if ($('#beesblogRecentPostSlider').length && !!$.prototype.bxSlider) {
        recent_post_carousel = $('#beesblogRecentPostSlider').bxSlider({
            minSlides: recent_post_carousel_items,
            maxSlides: recent_post_carousel_items,
            nextText: '<div class="recentpost__controls recentpost__controls--next"><svg class="recentpost__controls_ico"><use xlink:href="/themes/niara/img/symbol_sprite.svg#icon-slider_arrow"></use></svg></div>',
            prevText: '<div class="recentpost__controls recentpost__controls--prev"><svg class="recentpost__controls_ico"><use xlink:href="/themes/niara/img/symbol_sprite.svg#icon-slider_arrow"></use></svg></div>',
            slideWidth: 400,
            slideMargin: 40,
            pager: true,
            moveSlides: 1,
            infiniteLoop: false,
            hideControlOnEnd: true,
            responsive: true,
        });
    }
}
function resizeBeesblogRecentPostSlider() {
    countItemsRecentPost();
    recent_post_carousel.reloadSlider({
        minSlides: recent_post_carousel_items,
        maxSlides: recent_post_carousel_items,
        nextText: '<div class="recentpost__controls recentpost__controls--next"><svg class="recentpost__controls_ico"><use xlink:href="/themes/niara/img/symbol_sprite.svg#icon-slider_arrow"></use></svg></div>',
        prevText: '<div class="recentpost__controls recentpost__controls--prev"><svg class="recentpost__controls_ico"><use xlink:href="/themes/niara/img/symbol_sprite.svg#icon-slider_arrow"></use></svg></div>',
        slideWidth: 400,
        slideMargin: 40,
        pager: true,
        moveSlides: 1,
        infiniteLoop: false,
        hideControlOnEnd: true,
        responsive: true,
    });
}

// $('.recentpost__controls--prev').click(() => {
//     recent_post_carousel.goToPrevSlide();
// });
// $('.recentpost__controls--next').click(() => {
//     recent_post_carousel.goToNextSlide();
// });
