const select = {
    init() {
        $('.js--custom_select-option').click(function() {
            const $select = $(this).closest('.custom_select');
            const selectedDataValue = $(this).data('value');
            const selectedCaption = $(this).text();
            $select
                .find('.custom_select__selected')
                .attr('data-value', selectedDataValue)
                .html(selectedCaption);
            $select.closest('.form-group').removeClass('opc-has-error form-group--error');
            $select.find('.js--collapsible').collapse('hide');
        });
        $(document).click(function (e) {
            const clickOnSelect = $(e.target).closest('.custom_select').length > 0;
            if (!clickOnSelect) {
                $('.js--collapsible').collapse('hide');
            }
        });
    },
};

export default select;
