const index = {
    screen: window.matchMedia('(max-width: 1023px)'),
    actions: {},
    refreshWhenItNeeds() {
        if (index.screen.matches) {
            index.actions.on.forEach((func) => func());
        } else {
            index.actions.off.forEach((func) => func());
        }
    },
    mediaHandlers(actions) {
        index.actions = actions;
        index.refreshWhenItNeeds();
        index.screen.addEventListener('change', () => index.refreshWhenItNeeds());
    },
};
// export const tablet = {
//     screen: window.matchMedia('(min-width: 768px) and (max-width: 1199px)'),
//     actions: {},
//     refreshWhenItNeeds() {
//         if(tablet.screen.matches) {
//             tablet.actions.do.forEach(func => func())
//         } else {
//             tablet.actions.undo.forEach(func => func())
//         }
//     },
//     mediaHandlers(actions) {
//         tablet.actions = actions;
//         tablet.refreshWhenItNeeds();
//         tablet.screen.addEventListener('change', () => tablet.refreshWhenItNeeds());
//     }
// };

export default index;
