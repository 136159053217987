const chooseHelper = {
    init() {
        $(document).on('click', '.js--box_products__item--empty', () => {
            $('.box_products').addClass('box_products--hide_modal');
            $('.card__info_button').addClass('card__info_button--animate_basket').delay(2000).queue(function () {
                $(this).removeClass('card__info_button--animate_basket');
                $(this).dequeue(); // продолжаем выполнение очереди
            });
        });
    },
};

export default chooseHelper;
