const productBox = {
    classNames: {
        jsBoxProducts: '.js--box_products',
        jsShowBoxProducts: '.js--toggle_box_products',
        jsSendAjax: '.js--sendAjax',
        jsSendAjaxValue: '.js--sendAjax_value',
        jsConfirmRedirect: '.js--confirm_redirect',
        jsProductCombPrice: '.js--product_comb_price',
        jsProductCombFullPrice: '.js--product_comb_full_price',
    },
    initToggleBoxProducts() {
        $(productBox.classNames.jsShowBoxProducts).live('click', () => {
            $(productBox.classNames.jsBoxProducts).toggle();
        });
    },
    openFancyBox(content) {
        if ($.prototype.fancybox) {
            $.fancybox.open([
                {
                    type: 'inline',
                    autoWidth: true,
                    autoScale: true,
                    minWidth: 310,
                    minHeight: 30,
                    content,
                }], { padding: 0 });
        }
    },
    changePrice() {
        $(productBox.classNames.jsProductCombFullPrice).live('click', function () {
            $(productBox.classNames.jsProductCombPrice).html($(this).data('full-price'));
        });
    },
    sendAjax() {
        const getData = (item) => {
            const data = {};
            $(item).each(function () {
                if ($(this).attr('name') !== undefined) {
                    if ($(this).attr('type') === 'radio') {
                        if ($(this).attr('checked')) {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    } else {
                        data[$(this).attr('name')] = $(this).val();
                    }
                }
            });
            return data;
        };
        $(productBox.classNames.jsSendAjax).live('click', function sendAjax(e) {
            e.preventDefault();
            const $this = $(this);
            const type = $this.data('type');
            const setTo = $this.data('set');
            const confirmText = $this.data('confirm_text');
            const getContainer = $this.data('get_type');
            const send = (data) => {
                let currentData = data || getData($this.find(`${productBox.classNames.jsSendAjaxValue} :input`));
                if (getContainer === 'closest') {
                    currentData = getData($this.closest(productBox.classNames.jsSendAjaxValue).find('input'));
                }
                $.ajax({
                    url: `${baseUri}modules/ffcalc/ajax.php`,
                    method: type,
                    dataType: 'json',
                    data: currentData,
                    beforeSend() {
                        $this.addClass('button--preloader');
                    },
                    success(rsp) {
                        const rspHtml = rsp !== null ? rsp.html : null;
                        if (rsp !== null && rsp.result === 'error') {
                            productBox.openFancyBox(rspHtml);
                        } else if (rsp !== null && rsp.result === 'success') {
                            if (setTo === 'fancybox') {
                                productBox.openFancyBox(rspHtml);
                            } else if ($(setTo).length) {
                                const setType = $this.data('set_type');
                                if (rsp.next_page === false) {
                                    $this.hide();
                                }
                                if (setType === 'add') {
                                    console.log('add');
                                    $(setTo).append(rspHtml);
                                } else if (setType === 'replace') {
                                    console.log('replace');
                                    $(setTo).replaceWith(rspHtml);
                                } else {
                                    console.log('!replace');
                                    $(setTo).html(rspHtml);
                                }
                            }
                        }
                    },
                    complete() {
                        $this.removeClass('button--preloader');
                        if ($('.fancybox-close').length) {
                            $('.fancybox-close').click();
                        }
                    },
                });
            };
            if (confirmText) {
                const confirmYes = $this.data('confirm_yes');
                const confirmNo = $this.data('confirm_no');
                window.fancyConfirmClear = function clear() {
                    $('.js--add_preloader').addClass('button--preloader');
                    const data = getData($this.find(`${productBox.classNames.jsSendAjaxValue} :input`));
                    send(data);
                };
                productBox.openFancyBox(`<div class="fancybox-confirm">
                        <p>${confirmText}</p>
                        <div class="fancybox-confirm__button_wrapper">
                            <button class="button button--stroke button--small js--add_preloader" onclick="fancyConfirmClear()">${confirmYes}</button>
                            <button class="button button--small" onclick="fancyConfirmCancel()">${confirmNo}</button>
                        </div>
                    </div>`);
            } else {
                send();
            }
        });
    },
    confirmRedirect() {
        $(productBox.classNames.jsConfirmRedirect).live('click', function showModal(e) {
            e.preventDefault();
            const $this = $(this);
            const href = $this.attr('href');
            const confirmText = $this.data('confirm_text');
            const confirmYes = $this.data('confirm_yes');
            const confirmNo = $this.data('confirm_no');
            window.fancyConfirmRedirect = function clear() {
                $('.js--add_preloader').addClass('button--preloader');
            };
            productBox.openFancyBox(`<div class="fancybox-confirm">
                        <p>${confirmText}</p>
                        <div class="fancybox-confirm__button_wrapper">
                            <a class="button button--stroke button--small js--add_preloader" onclick="fancyConfirmRedirect()" href="${href}">${confirmYes}</a>
                            <button class="button button--small" onclick="fancyConfirmCancel()">${confirmNo}</button>
                        </div>
                    </div>`);
        });
    },
    init() {
        window.fancyConfirmCancel = () => {
            $('.fancybox-close').click();
        };
        productBox.initToggleBoxProducts();
        productBox.sendAjax();
        productBox.changePrice();
        productBox.confirmRedirect();
    },
};

export default productBox;
