const scrollUp = {
    init() {
        let scrollItem = $('.scroll-up');
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                scrollItem.addClass('show-scroll');
            } else {
                scrollItem.removeClass('show-scroll');
            }
        });
        scrollItem.click(() => {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });
    },
};

export default scrollUp;

