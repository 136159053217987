const counter = {
    inputName: '.js--counter__text',
    jsCounter: '.js--counter',
    resizeInput(input) {
        const { length } = input.val();
        input.attr('size', length === 0 ? 1 : length);
    },
    init() {
        $('.js--counter__minus').live('click', function minus() {
            const $input = $(this).closest(counter.jsCounter).find(counter.inputName);
            let count = parseInt($input.val(), 10) - 1;
            count = count < 1 ? 1 : count;
            if ($input.length === 1) {
                $input.val(count);
            } else if ($input.length > 1) {
                $input.each(function currentInputSetValue() {
                    $(this).val(count);
                });
            }
            $input.keypress();
            counter.resizeInput($input);
            return false;
        });
        $('.js--counter__plus').live('click', function plus() {
            const $input = $(this).closest(counter.jsCounter).find(counter.inputName);
            if ($input.length === 1) {
                $input.val(parseInt($input.val(), 10) + 1);
            } else if ($input.length > 1) {
                $input.each(function currentInputSetValue() {
                    $(this).val(parseInt($(this).val(), 10) + 1);
                });
            }
            $input.keypress();
            counter.resizeInput($input);
            return false;
        });
    },
};
export default counter;
