const menu = {
    initMenu() {
        $('.js--menu_toggle').click(() => {
            const $menu = $('.js--menu_collapse');
            if ($menu.hasClass('menu__collapse--show')) {
                $('body').removeClass('body--overflow');
                $menu.addClass('menu__collapse--hide');
                setTimeout(() => {
                    $menu.removeClass('menu__collapse--show');
                }, 400);
            } else {
                $menu.removeClass('menu__collapse--hide').addClass('menu__collapse--show');
                $('body').addClass('body--overflow');
            }
        });
    },
};

export default menu;
