import 'bootstrap';
// import 'moment/moment';
import 'bootstrap-datepicker/js/bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ru';
import AOS from 'aos';

import menu from './components/menu';
import scrollUp from './components/scrollUp';
import filterLength from './components/filterLength';
import form from './components/ui/form/form';
import mobile from './_helpers/js/match_media_util';
import textBlock from './components/text_block';
import counter from './components/ui/counter/counter';
import tooltipInit from './components/ui/tooltip';
import customSelect from './components/ui/custom_select';
import datePicker from './components/ui/date_picker';
import './_helpers/js/beesblog_recent_post_slider/index';

// Pages
import productBox from './pages/product-box/product-box'; // Todo remove in product.js
import './pages/stores/stores';
import chooseHelper from './components/chooseHelper';

window.ff_functions = {
    validation: form.init,
    initDatePicker: datePicker.init,
};

$(document).ready(() => {
    form.init();
    customSelect.init();
    productBox.init();
    scrollUp.init();
    filterLength.init();
    tooltipInit();
    menu.initMenu();
    counter.init();
    chooseHelper.init();
    AOS.init({
        duration: 500,
        offset: 50,
        once: true,
    });
    mobile.mediaHandlers({
        on: [
            textBlock.initBxSlider,
        ],
        off: [
            textBlock.destroyBxSlider,
        ],
    });

    $('.js--socialButton').click(() => {
        if ($('.beesblog-content__social_list').hasClass('active')) $('.beesblog-content__social_list').removeClass('active');
        else $('.beesblog-content__social_list').addClass('active');
    });
});
