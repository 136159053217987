import formHandler from './form_handler';

const validation = {
    init() {
        jQuery.validator.addMethod('whitespaceonly', function (value, element) {
            return this.optional(element) || !/^\s+$/.test(value);
        });
        jQuery.validator.addMethod('selected', (value, element) => value.length > 0);

        $('.js--validate_form').each((i, form) => {
            const $password = $(form).find('input[name="passwd"]');
            const password_confirmation = 'password_confirmation';
            const confirmation = 'confirmation';
            const $password_confirmation = $(form).find('input[name="password_confirmation"]');
            const $confirmation = $(form).find('input[name="confirmation"]');

            $(form).validate({
                ignore: '',
                rules: {
                    [confirmation]: {
                        equalTo: $password,
                    },
                    [password_confirmation]: {
                        equalTo: $password,
                    },
                },
                messages: {
                    [password_confirmation]: {
                        equalTo: $password_confirmation.data('error-confirmation'),
                    },
                    [confirmation]: {
                        equalTo: $confirmation.data('error-confirmation'),
                    },
                },
                errorElement: 'span',
                errorPlacement(error, element) {
                    error.appendTo(element.siblings('.form__error_wrapper'));
                },
                highlight(element) {
                    $(element.closest('.form-group')).addClass('form-group--error');
                    $(element.closest('.form-group')).removeClass('form-group--ok');
                },
                unhighlight(element) {
                    $(element.closest('.form-group')).removeClass('form-group--error');
                    $(element.closest('.form-group')).addClass('form-group--ok');
                },
                submitHandler() {
                    $(form).find('input').not('input[name="password"]').val((i, val) => val.trim());
                    $(form).find('textarea').val((i, val) => val.trim());
                    switch ($(form).attr('id')) {
                        case 'callbackForm':
                            formHandler.setCurrentForm(this).callbackForm();
                            break;
                        case 'login_form':
                            formHandler.setCurrentForm(this).auth();
                            break;
                        case 'account-creation_form':
                            formHandler.setCurrentForm(this).auth();
                            break;
                        case 'form_forgotpassword':
                            formHandler.setCurrentForm(this).defaultSubmit();
                            break;
                        case 'identity_form':
                            formHandler.setCurrentForm(this).defaultSubmit();
                            break;
                        case 'create-account_form':
                            window.ff_registration_submit(validation.init);
                            break;
                        default:
                            break;
                    }
                },
            });
            $('.rule-minlength').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        minlength: 5,
                        messages: {
                            minlength: $(field).data('error-minlength'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-email').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        email: true,
                        messages: {
                            email: $(field).data('error-email'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-date').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        required: true,
                        messages: {
                            required: $(field).data('error-date'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-whitespace').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        whitespaceonly: true,
                        messages: {
                            whitespaceonly: $(field).data('error-whitespace'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-required').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        required: true,
                        messages: {
                            required: $(field).data('error-required'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-selected').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        selected: true,
                        messages: {
                            selected: $(field).data('error-selected'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-numbers').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        number: true,
                        messages: {
                            number: $(field).data('error-numbers'),
                        },
                    });
                }, 0, this);
            });
            $('.rule-phone').each(function () {
                setTimeout((field) => {
                    $(field).rules('add', {
                        required: true,
                        messages: {
                            required: $(field).data('error-required'),
                        },
                    });
                    $(field).rules('add', {
                        minlength: 19,
                        messages: {
                            minlength: $(field).data('error-tel'),
                        },
                    });
                }, 0, this);
            });
        });
    },
};

export default validation;
