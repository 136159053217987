const date = {
    init() {
        const date = new Date();
        date.setDate(date.getDate());
        $('.js--date-pick').datepicker({
            format: 'yyyy-mm-dd',
            autoclose: true,
            language: 'ru',
            orientation: 'top-bottom',
            container: '.js--data-picker-container',
            templates: {
                leftArrow: '<div class="date_picker__control date_picker__control--prev"></div>',
                rightArrow: '<div class="date_picker__control date_picker__control--next"></div>',
            },
            startDate: date,
        });
    },
};

export default date;
